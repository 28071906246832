@import 'bootstrap/scss/bootstrap.scss';
@import 'flag-icons/sass/flag-icons';
@import './shared-ui-variables.scss';
@import '~primeicons/primeicons.css';
@import './variables.scss';
@import './tgx.scss';

// storybook custom styles
// display none for docs' control column
.css-tm6i3o.css-tm6i3o td:last-of-type {
  display: none;
}

.css-tm6i3o.css-tm6i3o tr:last-child td:last-child,
.css-tm6i3o.css-tm6i3o tr:last-child th:last-child {
  display: none;
}

.sbdocs-h3 {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 6px !important;
}
